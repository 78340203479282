<template>
	<div>
		<validation-observer ref="simpleRules">
			<form-wizard
				color="#296db4"
				:title="null"
				:subtitle="null"
				finish-button-text="Submit"
				back-button-text="Previous"
				skip-button-text="Skip"
				class="steps-transparent mb-3"
				@on-complete="updateFarm()"
			>
				<!-- Poultrac detail tab -->
				<tab-content title="Farm Details" icon="feather icon-file-text">
					<b-row>
						<b-col cols="12" class="mb-2">
							<div class="d-flex">
								<feather-icon icon="ArrowLeftIcon" size="24" class="mr-2" @click="goBack" />

								<div>
									<h5 class="mb-0">Farm Details</h5>
									<small class="text-muted"> Enter Your Farm Details. </small>
								</div>
							</div>
						</b-col>
						<b-col md="6">
							<b-form-group>
								<template v-slot:label> Farm Name<span class="text-danger">*</span> </template>
								<validation-provider #default="{ errors }" name="Farm Name" rules="required">
									<b-form-input name="farm_name" v-model="formData.name" placeholder="" />
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>
						<b-col cols="12" class="mb-2 mt-5">
							<h5 class="mb-0">Address Information</h5>
						</b-col>
						<b-col md="12">
							<b-form-group>
								<template v-slot:label> Address<span class="text-danger">*</span> </template>
								<validation-provider #default="{ errors }" name="Address" rules="required">
									<b-form-textarea name="address" v-model="formData.address" placeholder="" rows="3" />
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>
						<b-col md="6">
							<b-form-group>
								<template v-slot:label> Country<span class="text-danger">*</span> </template>
								<v-select
									v-model="formData.country"
									:options="countryList"
									value-field="id"
									label="name"
									:reduce="(e) => e.id"
									@input="getState"
								/>
							</b-form-group>
						</b-col>
						<b-col md="6">
							<b-form-group>
								<template v-slot:label> State<span class="text-danger">*</span> </template>
								<v-select
									v-model="formData.state"
									:options="stateList"
									value-field="id"
									label="name"
									:reduce="(e) => e.id"
									@input="getCity"
								/>
							</b-form-group>
						</b-col>

						<b-col md="6">
							<b-form-group>
								<template v-slot:label> City<span class="text-danger">*</span> </template>
								<v-select
									v-model="formData.city"
									:options="cityList"
									:reduce="(e) => e.id"
									label="name"
									value-field="id"
								/>
							</b-form-group>
						</b-col>
						<!-- 
						<b-col md="6">
							<b-form-group>
								<template v-slot:label> Pincode<span class="text-danger">*</span> </template>
								<validation-provider #default="{ errors }" name="Pincode" rules="required">
									<b-form-input
										name="pincode"
										type="text"
										v-model="formData.pin_code"
										placeholder=""
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col> -->
					</b-row>
				</tab-content>
			</form-wizard>
		</validation-observer>
	</div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { heightTransition } from "@core/mixins/ui/transition";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import Ripple from "vue-ripple-directive";
import axiosIns from "@/libs/axios";
import { BASE_URL } from "@core/common/constants";

export default {
	components: {
		FormWizard,
		TabContent,
		vSelect,
		ValidationProvider,
		ValidationObserver,
	},
	directives: {
		Ripple,
	},
	mixins: [heightTransition],
	data() {
		return {
			items: [
				{
					id: 0,
					prevHeight: 0,
				},
			],
			companyId: null,
			selectRole: null,
			selectName: null,
			roleWiseList: null,
			nextTodoId: 2,
			stateList: [],
			countryList: [],
			cityList: [],
			userList: [],
			company_id: null,
			companyRoles: [],
			formData: {
				farm_name: null,
				pin_code: null,
				address: null,
				selectedState: null,
				selectedCountry: null,
				selectedCity: null,
			},
		};
	},
	created() {
		this.companyId = this.$route.params.companyId;
		this.getCountry();
		this.getFarmDetails();
	},
	methods: {
		goBack() {
			this.$router.go(-1);
		},
		roleLabel(option) {
			return option.name;
		},
		userLabel(option) {
			return option.name;
		},
		getFarmDetails() {
			let farmId = this.$route.params.farmId;
			axiosIns
				.get(`web/edit-farm/${farmId}`)
				.then((response) => {
					this.formData = response.data.farm_list;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.error);
				});
		},
		getState(id) {
			axiosIns
				.get(`web/get-state-list?country_id=${id}`)
				.then((response) => {
					this.stateList = response.data.state_list;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
		getCountry() {
			axiosIns
				.get(`web/get-district-list`)
				.then((response) => {
					this.countryList = response.data.country_list;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
		getCity(id) {
			axiosIns
				.get(`web/get-city-list?district_id=${id}`)
				.then((response) => {
					this.cityList = response.data.city_list;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
		updateFarm(e) {
			// e.preventDefault();
			const farmId = this.$route.params.farmId;
			this.$refs.simpleRules.validate().then((success) => {
				if (success) {
					let data = new FormData();
					let form_data = this.prepareForm(this.formData, data);

					let url = `web/update-farm/${farmId}`;
					axiosIns
						.post(url, form_data)
						.then((res) => {
							let response_data = res.data.message;
							this.$toast({
								component: ToastificationContent,
								position: "top-right",
								props: {
									// title: `Welcome `,
									icon: "CoffeeIcon",
									variant: "success",
									text: response_data,
								},
							});
							this.$router.push({
								path: `/apps/farm/list`,
							});
						})
						.catch((error) => {
							const data = error.response.data.message;
							this.$toast({
								component: ToastificationContent,
								position: "top-right",
								props: {
									// title: `Welcome `,
									icon: "CoffeeIcon",
									variant: "danger",
									text: data,
								},
							});
						});
				}
			});
		},
	},
};
</script>
<style lang="scss" scoped>
.repeater-form {
	overflow: hidden;
	transition: 0.35s height;
}
.role-button {
	background-color: rgba(41, 109, 180, 1);
	border-color: rgba(41, 109, 180, 1);
	color: white;
	width: 170px;
	display: flex;
	padding: 10px 10px;
	justify-content: center;
	align-items: center;
	border-radius: 5px;
}
.custom {
	margin-left: 1rem;
}
@import "@core/scss/vue/pages/nav-role.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
